

const NavBar = () => {

    return (
        //Main
 

<nav class="bg-white border-gray-200 bg-gradient-to-l from-indigo-400 to-indigo-950">
  <div class=" flex  items-center justify-between w-full  p-4">
    <h1 href="" class="flex items-center space-x-3 rtl:space-x-reverse">
        <span class="self-center text-2xl font-semibold whitespace-nowrap dark:text-white">MirandaBetweenLines</span>
    </h1>

  </div>
</nav>

          
    )
}
export default NavBar